<template>
  <div class="mapModalOrder border rounded" style="width: 400px;">
    <div class="borderInfo border shadow-lg bg-white rounded">
      <div class="d-flex justify-content-center mt-1" @click="isFiniteMap()">
        <img class="mb-1" :src="require('@/assets/images/icons/Calendar2Fill.svg')">
        <h4 class="ml-4">Информамация</h4>
        <img class="imgmargin"  :class="{ 'durationIs': is_activeGPS }" :src="require('@/assets/images/icons/ChevronUp.svg')">
      </div>
      <div v-if="!is_activeGPS" class="d-flex justify-content-center mt-1">
        <div class="mt-3 mx-2">
          <img @click="geoData()" :src="require('@/assets/images/icons/Crosshair2.svg')">
        </div>
        <div>
          <p>Состояние автомобиля (текущее)</p>
          <p>{{ performer.performer_transport }}</p>
          <p v-if="performer.is_active === 1">
            Свободен
          </p>
          <p v-else>
            Занять
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
export default {
  components: {
    FeatherIcon
  },
props:['performer'],
data() {
  return {
    is_activeGPS: false,
  }
},
methods: {
  geoData() {
    this.$root.$emit('geoData')
  },
  isFiniteMap() {
    this.is_activeGPS = !this.is_activeGPS
  }
}
}
</script>

<style scoped>
.mapModalOrder {
    overflow: auto;
}
.borderInfo{
  margin: 10px;

}
.durationIs {
  animation-duration: 750ms;
  transform: rotate(3.142rad);
}
.imgmargin{
  margin-left: 50px;
  margin-bottom: 10px;
}
p {
  font-size: 15px;
}
</style>